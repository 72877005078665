<template>
  <b-row class="">
    <b-col>
      <b-card
        class=""
        @click="$router.push({ name: 'apps-users-list' })"
      >
        <app-timeline>

          <app-timeline-item>
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6 class="font-weight-bolder">10 Mai 2022</h6>
              <small class="text-muted">version 1.6.3</small>
            </div>
            <p><strong class="text-primary">Nouveau</strong> : Nouveau système de mise à jour.</p>
            <p><strong class="text-primary">Nouveau</strong> : Page de maintenance lorsque le logiciel est en maintenance.</p>
            <p><strong class="text-warning">Rectification</strong> : Amélioration des performances générales.</p>
          </app-timeline-item>

          <app-timeline-item>
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6 class="font-weight-bolder">10 Mai 2022</h6>
              <small class="text-muted">version 1.6.3</small>
            </div>
            <p><strong class="text-warning">Rectification</strong> : Suppression d'un bug concernant les filtres.</p>
            <p><strong class="text-warning">Rectification</strong> : Supression de la possibilité de supprimer un ticket en tant que "Maintenance". Seuls les administrateurs ont la permission.</p>
          </app-timeline-item>

          <app-timeline-item>
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6 class="font-weight-bolder">28 Avril 2022</h6>
              <small class="text-muted">version 1.6.3</small>
            </div>
            <p><strong class="text-warning">Rectification</strong> : Suppression d'un bug empéchant de modifier un ticket.</p>
            <p><strong class="text-warning">Rectification</strong> : "Assigné à" replacé par "Traité par".</p>
          </app-timeline-item>

          <app-timeline-item>
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6 class="font-weight-bolder">26 Avril 2022</h6>
              <small class="text-muted">version 1.6.0</small>
            </div>
            <p><strong class="text-primary">Nouveau</strong> : Nouvelle fenêtre de détails d'un ticket.</p>
            <p><strong class="text-primary">Nouveau</strong> : Possibilité de filtrer les adresse IP autorisées à accéder au logiciel.</p>
            <p><strong class="text-warning">Rectification</strong> : Ajustement du design du panneau latéral d'ajout/modification d'un ticket.</p>
          </app-timeline-item>

          <app-timeline-item>
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6 class="font-weight-bolder">28 Mars 2022</h6>
              <small class="text-muted">version 1.5.0</small>
            </div>
            <p><strong class="text-primary">Nouveau</strong> : Nouvelle interface. Ajustement des marges pour une meilleure lisibilité.</p>
            <p><strong class="text-primary">Nouveau</strong> : Possibilité de changer le statut du ticket dans ouvrir ce dernier.</p>
            <p><strong class="text-primary">Nouveau</strong> : Vue compacte/détaillée.Possibilité d'ajuster la vue des tickets. La vue compacte correspond à l'ancienne vue, la vue détaillée permet d'afficher plus d'informations en un coup d'oeil.</p>
            <p><strong class="text-primary">Nouveau</strong> : Les tickets sont maintenants organisés dans 4 grandes catégories (filtres) : Tous, à traiter, en cours, cloturés.</p>
            <p><strong class="text-info">Modification</strong> : La panneau latéral affichant les détails du ticket a été modifié ainsi que le paneau latéral de modification d'un ticket.</p>
            <p><strong class="text-warning">Rectification</strong> : Ajustement du design pour un affichage sur un smartphone ou tablette.</p>
          </app-timeline-item>

          <app-timeline-item>
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6 class="font-weight-bolder">15 Mars 2022</h6>
              <small class="text-muted">version 1.4.0</small>
            </div>
            <p><strong class="text-info">Modification</strong> : Amélioration des perfdormances générales.</p>
          </app-timeline-item>

          <app-timeline-item>
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6 class="font-weight-bolder">24 Février 2022</h6>
              <small class="text-muted">version 1.3.0</small>
            </div>
            <p><strong class="text-primary">Nouveau</strong> : Nouveau look général</p>
            <p><strong class="text-primary">Nouveau</strong> : La liste des tickets affiche maintenant les tickets des 30 derniers jours</p>
            <p><strong class="text-primary">Nouveau</strong> : Système de filtre par périodes prédéfinies et sur mesure</p>
            <p><strong class="text-warning">Rectification</strong> : Filtrer les tickets par statut "à traiter" fonctionne de nouveau normalement.</p>
          </app-timeline-item>

          <app-timeline-item>
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6 class="font-weight-bolder">05 Février 2022</h6>
              <small class="text-muted">version 1.2.1</small>
            </div>
            <p><strong class="text-primary">Nouveau</strong> : L'affichage des tickets est maintenant plus rapide de 14%.</p>
            <p><strong class="text-info">Modification</strong> : Il est désormais impossible de supprimer la priorité d'un ticket en cliquant sur la petite croix dans la liste déroulante.</p>
            <p><strong class="text-info">Modification</strong> : Suppression du boutton d'ajout de lien dans la description d'un ticket.</p>
            <p><strong class="text-warning">Rectification</strong> : La liste des statuts ne s'affichait pas pour dans certains navigateurs, une correction a été apprortée.</p>
          </app-timeline-item>

          <app-timeline-item>
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6 class="font-weight-bolder">09 Décembre 2021</h6>
              <small class="text-muted">version 1.3.0</small>
            </div>
            <p><strong class="text-primary">Nouveau</strong> : La liste des tickets se raffraichit automatiquement et peut aussi être mise à jour en cliquant sur le bouton en haut à droite de la liste (compte à rebours).</p>
            <p><strong class="text-warning">Rectification</strong> : Il est désormais impossible d'aller sur la page de connexion si vous êtes identifié, il est nécessaire de se déconnecter avant.</p>
            <p><strong class="text-warning">Rectification</strong> : Rectification d'un bug présent sur certains navigateurs, affichant la traduction automatique.</p>
          </app-timeline-item>

          <app-timeline-item>
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6 class="font-weight-bolder">24 Novembre 2021</h6>
              <small class="text-muted">version 1.1.1</small>
            </div>
            <p><strong class="text-info">Modification</strong> : Il est désormais possible d'afficher un message au dessus de la liste de tickets. Les adminsitrateurs peuvent définir ce message depuis le paneau d'administration > paramètres.</p>
          </app-timeline-item>

          <app-timeline-item>
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6 class="font-weight-bolder">20 Novembre 2021</h6>
              <small class="text-muted">version 1.1.0</small>
            </div>
            <p><strong class="text-primary">Nouveau</strong> : Vous pouvez désormais choisir si les tickets sont privés ou publics. Cela permet de décider si les utilisateurs peuvent accéder à tous les tickets ou seulement les leurs et ceux de leur service attitré (pack 2).</p>
            <p><strong class="text-info">Modification</strong> : Le panneau d'administration est désormais accessible depuis le menu déroulant utilisateur (en cliquant sur votre nom en haut à droite).</p>
          </app-timeline-item>

        </app-timeline>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  VBToggle, BCard, VBTooltip,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  created() {
    this.$store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'vertical')
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    this.$store.commit('appConfig/UPDATE_SKIN', 'semi-dark')
  },
  data() {
    return {
      contentWidth: this.$store.state.appConfig.layout.contentWidth,

    }
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', this.contentWidth)
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
